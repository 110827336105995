/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MASTER_DEALERID, BASE_URI } from "../shared/Constants";
import { ContactUs as Form } from "@red-build/leadcentre";
import { DealerContext } from '../shared/contexts/DealerContext';
import Divider from '@mui/material/Divider';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: '130px',
    marginBottom: '60px'
  },
  innerSection: {
    width: "100%",
    maxWidth: "1360px",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center',
    padding: "6px 15px",
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "0px 0px",
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '50vw',
    margin: '5rem 0rem 0rem 0rem',
    '&:nth-child(1)': {
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      '&:nth-child(1)': {
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
    maxWidth: '70%',
  },
  textBold: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: "20px"
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: '#209287',
    fontWeight: 'bold'
  },
  mapHolder: {
    width: '50%',
    padding: "20px 0px"
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  middleSubtitle: {
    fontSize: "1.5rem",
    color: "#466574",
    textAlign: 'center',
    marginTop: '20px',
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto'
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
  dealerInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: "20px 0px"
  },
  title2: {
    textAlign: 'center',
    color: "#466574",
    fontSize: '2rem'
  }
}))

const defaultColors = {
  primaryColor: "#083246",
  secondaryColor: "#EFDF00",
};

const ContactUs = () => {
  const classes = useStyles();
  const { globalDealer, dealerList } = useContext(DealerContext)
  const [activeDealer, setActiveDealer] = useState();
  const [dealerLinks, setDealerLinks] = useState();
  const hours = 'Monday - Friday: 09:00 - 17:00, Saturday: 08:00 - 13:00';

  useEffect(() => {
    if (globalDealer?.dealerId) {
      const getDealerWebsites = async (dealerID) => {
        axios({
          method: 'GET',
          url: `${BASE_URI}/DealerWebsites/${dealerID}`,
        })
          .then((res) => {
            const oLinks = res.data.links.reduce((ac, v) => {
              return { ...ac, [v?.name.replace(' ', '')]: v?.link }
            }, {});
            setDealerLinks(oLinks);
          })
          .catch(() => null);
      };

      getDealerWebsites(globalDealer.dealerId);
    }
    if (globalDealer?.dealerId !== MASTER_DEALERID) {
      setActiveDealer(dealerList.filter(d => d?.dealerId == globalDealer?.dealerId)[0])
    } else {
      setActiveDealer(dealerList[0])
    }
  }, [dealerList, globalDealer])

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.middleNew}>
          <Typography variant="h1" className={classes.middleTile}>
            <strong><span className={classes.newSecondaryColor}>Leave</span> Us A Message</strong>
          </Typography>
          <Typography variant="h2" className={classes.middleSubtitle}>
            Leave a message, we will get back to you.
          </Typography>
          <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
        </div>
        <div className={classes.section} >
          <div className={classes.dealerInfo}>
            <Typography variant="h3" className={classes.title2}>
              {activeDealer?.name}
            </Typography>
            <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 20 }}>
              <Typography variant="body2" className={classes.text}>
                Contact Us
              </Typography>
              <a
                className={classes.text}
                style={{ padding: '0px', color: 'black' }}
                href={`tel:${activeDealer?.newCarsPhoneNumber.replace(/ /g, '').replace(/^0/g, '+27')}`} target="_blank" rel="noreferrer">
                <Typography variant="body2" style={{ textAlign: 'right', fontSize: '16px', }}>
                  {activeDealer?.newCarsPhoneNumber}
                </Typography>
              </a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 20 }}>
              <Typography variant="body2" className={classes.text}>
                Address
              </Typography>
              <a
                className={classes.text}
                style={{ padding: '0px', color: 'black' }}
                href={
                  dealerLinks?.mapsURL
                    ? dealerLinks.mapsURL
                    : `https://www.google.com/maps/@${activeDealer?.addressLongitude},${activeDealer?.addressLatitude},16z?entry=ttu`
                }
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="h3" style={{ textAlign: 'right', fontSize: '16px', }}>
                  {activeDealer?.address?.streetAddress}, {activeDealer?.address?.streetAddressPostal}
                </Typography>
              </a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 20 }}>
              <Typography variant="h3" className={classes.text}>
                Business hours
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'right'}}>
                  <Typography  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                {!activeDealer?.operatingHours?hours.split(',').map((item) => (
                  <>
                    {item.trim()}
                    <br /> {/* Adds a line break after each part */}
                  </>
                )):activeDealer?.operatingHours?.split(',').map((item) => (
                  <>
                    {item.trim()}
                    <br /> {/* Adds a line break after each part */}
                  </>
                ))}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.mapHolder}>
            <iframe
              title='Map to Dealer'
              src={
                dealerLinks?.mapsEmbed
                  ? dealerLinks.mapsEmbed
                  : `https://maps.google.com/maps?q=${activeDealer?.addressLongitude},${activeDealer?.addressLatitude}&via=0&z=16&output=embed`
              }
              height="100%"
              width="100%"
              frameBorder="none">
            </iframe>
          </div>
        </div>
        <div className={classes.section} style={{ flexDirection: 'column' }}>
          <Typography variant="h3" className={classes.title2}>
            Enquire Now
          </Typography>
          <div className={classes.deviderContainer} style={{ paddingBottom: '20px' }}><Divider className={classes.newMiddleDivider} /></div>
          <Form
            base_uri={BASE_URI}
            leadTypeId={3}
            dealerId={globalDealer?.dealerId}
            masterDealerId={MASTER_DEALERID}
            dealerName={globalDealer?.name
              ?.replace("Master", "")}
            dealers={dealerList}
            dealerType="multi_dealer"
            websiteColors={defaultColors}
            note={true}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactUs